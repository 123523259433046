.footer {
  width: 100%;
  height: 200px;
  background: #f4f7f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.socialMedia svg {
  color: black;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  margin-top: 2%;
  color: black;
}

@media screen and (max-width:360px) {
  .footer {
    width: 110%;
  }
}