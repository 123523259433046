.about {
    width: 100%;
    height: 120vh;
    display: flex;
    flex-direction: column;
  }
  
  .aboutTop {
    width: 100%;
    height: 85%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .aboutBottom {
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 5%;
  }
  
  .aboutBottom p {
    margin-top: 1%;
    margin-bottom: 1%;;
    width: 50%;
    color: black;
    font-size: large;
    text-align: center;
  }

  .about .aboutBottom ul {
    font-size: large;
    font-weight: bold;
    list-style-type: circle;
  }
  
  .aboutBottom img {
      width: 500px;
      margin-top: 1%;
      margin-left: 10px;
  }
  
  .about .aboutBottom h1 {
    font-weight: 400;
    font-size: 70px;
    color: black;
    height: 30px;
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
  }

  @media (max-width: 1550px) {
    .about .aboutBottom h1 {
        font-size: 50px;
      }

    .aboutBottom img {
        width: 330px;
        margin-top: 1%;
    }
}

@media (max-width: 1100px) {
    .about .aboutBottom h1 {
        font-size: 50px;
      }

    .aboutBottom img {
        width: 250px;
        margin-top: 1%;
    }
}


  @media (max-width: 800px) {
    .aboutBottom img {
        width: 200px;
        margin-top: 1%;
    }

    .about .aboutBottom h1 {
        font-size: 42px;
      }

      .aboutBottom p {
          font-size: medium;
          width: 80%;
      }
}

@media (max-width: 650px) {

      .aboutBottom p {
        font-size: medium;
        width: 80%;
      }

    .aboutBottom img {
        width: 150px;
        margin-top: 1%;
    }
}

@media (max-width: 520px) {

  .about .aboutBottom h1 {
    font-size: 36px;
  }

  .aboutBottom p {
    font-size: medium;
    width: 80%;
  }

.aboutBottom img {
    width: 100px;
    margin-top: 1%;
}


@media (max-width: 650px) {
  .about .aboutBottom h1 {
    font-size: 32px;
  }

  .about .aboutBottom ul {
    font-size: medium;
  }

  .aboutTop {
    width: 110%;
  }
}

@media (max-width: 350px) {
  .about .aboutBottom h1 {
    font-size: 24px;
  }

  .aboutBottom img {
    width: 80px;
    margin-top: 0%;
  }

  .about .aboutBottom ul {
    font-size: 13px;
  }
}
}
