.hero-img {
    background: url('../assets/img26.jpg');
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-container {
    margin-left: 50px;
    text-align: center;
 
    margin: auto;
    width: 60%;
    height: 33%;
    top: 40vh;
    background: rgba(0, 0, 0, .5);
  }

.header-container h1 {
    font-size: 5rem;
    color: #f2f2f2;
    content: "";
    width: 100%;
    text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
    margin-bottom: 1rem;
}

.header-container p {
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5rem;
    color: #f2f2f2;
}

.hero-img .btn {
    margin: 1rem .2rem;
}

@media screen and (max-width:1460px) {
    .header-container {
        width: 85%;
        height: 43%;
    }
}

@media screen and (max-width:1100px) {
    .header-container h1 {
        font-size: 3rem;
    }

    .header-container p {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }

    .header-container {
        width: 80%;
        height: 25%;
    }
}

@media screen and (max-width:960px) {
    .header-container {
        width: 80%;
        height: 25%;
    }
}

@media screen and (max-width:800px) {
    .header-container {
        width: 80%;
        height: 30%;
    }
}

@media screen and (max-width:640px) {

    .header-container h1{
        margin-top: 15%;
    }

    .header-container {
        width: 100%;
        height: 40%;
    }
}

@media screen and (max-width:540px) {

    .hero-img .header-container .btn {
        font-size: small;
    }

    .header-container h1{
        font-size: 1.8rem;
        margin-top: 15%;
    }

    .header-container {
        width: 100%;
        height: 32%;
    }
}

@media screen and (max-width:375px) {

    .hero-img .header-container .btn {
        font-size: small;
    }

    .header-container h1{
        font-size: 1.6rem;
        margin-top: 15%;
    }

    .header-container {
        width: 100%;
        height: 35%;
    }

    .hero-img {
        width: fit-content;
    }
}